//接口请求域名
var API_URL = "api/";
if(process.env.NODE_ENV == 'development'){
	API_URL = "api/api/";
}else{
	API_URL = "api/";
}
import $ from 'jquery';
// import * as Api from '../api/Data';
//序列化json对象
function serializationJson(params){
	let qs = require('qs');
	return qs.stringify(params);
}
//接口地址
export default{
		getemailcode:API_URL + "crowdfunding/offline/forgetpwd/email",//邮箱验证码
		loginUrl: API_URL + 'uaa/oauth/token',
		ownerData:API_URL + 'member/login/index',
		register:API_URL + 'crowdfunding/offline/user/register',
		userinfo:API_URL + 'crowdfunding/user/index',
		userdetail:API_URL + 'crowdfunding/user/detail',
		getprojectlist:API_URL + 'crowdfunding/offline/project/list',//获取项目列表
		applyproject:API_URL + 'crowdfunding/user/apply',//申购
		getapplylist:API_URL + 'crowdfunding/user/apply/list',//获取申请列表
		getinvestlist:API_URL + 'crowdfunding/user/invest/list',//获取投资列表
		getbenefitdetail:API_URL + 'crowdfunding/benefit/project',//获取收益列表
		contactusform:API_URL + 'crowdfunding/offline/contact/we',//联系我们
		noticelist:API_URL + 'crowdfunding/notice/list',//通知列表
		kakunincode:API_URL + 'crowdfunding/offline/forgetpwd/code',//确认验证码
		newpwd:API_URL + 'crowdfunding/offline/forgetpwd/new',//新密码
		getfilelist:API_URL + 'crowdfunding/user/invest/pdf',//书类一覧
		getaccounthistory:API_URL + 'crowdfunding/benefit/account/history',//获取收益一覧
		withdrawal:API_URL + 'crowdfunding/benefit/withdrawal',//提现
		sendcode:API_URL + 'member/user/emailLoginCode',//发送验证码
		sendmobilecode:API_URL + 'mss/smsCode',//发送验证码
		getnoticenum:API_URL + 'crowdfunding/notice/statistics',//未读消息
		checknotice:API_URL + 'crowdfunding/notice/check',//已读消息
		withdrawapply:API_URL + 'crowdfunding/user/withdraw',//撤回
		updatecredentials:API_URL + 'crowdfunding/user/update/credentials',//更新证件
		sendemail:API_URL + 'crowdfunding/user/send/email/code',//绑定发送验证码
		checkemail:API_URL + 'crowdfunding/user/check/email/code',//确认邮箱
		getofflinedetail:API_URL + 'crowdfunding/offline/project/detail',//项目详情
		changeborrow:API_URL + 'crowdfunding/user/change/borrow',//转借款
}
