import CryptoJS from 'crypto-js'
// console.log(CryptoJS);
/**
 * AES 加密
 * @param word: 需要加密的文本
 * KEY: // 需要前后端保持一致
 * mode: ECB // 需要前后端保持一致
 * pad: Pkcs7 //前端 Pkcs7 对应 后端 Pkcs5
 */
const KEY = CryptoJS.enc.Utf8.parse('ookawasebirukura');
const IV = CryptoJS.enc.Utf8.parse('fkabushikigaisha');
export default {
  AESEncrypt: function(plaintext){
    let encrypt = CryptoJS.AES.encrypt(plaintext, KEY, {
      iv:IV,
      mode: CryptoJS.mode.CBC,            //这里可以选择AES加密的模式
      padding: CryptoJS.pad.Pkcs7
    })
    // console.log(encrypt);
    return CryptoJS.enc.Base64.stringify(encrypt.ciphertext);
  }
}
// export const AESEncrypt = (plaintext) => {
//   let encrypt = CryptoJS.AES.encrypt(plaintext, KEY, {
//     iv:IV,
//     mode: CryptoJS.mode.CBC,            //这里可以选择AES加密的模式
//     padding: CryptoJS.pad.Pkcs7
//   })
//   // console.log(encrypt);
//   return CryptoJS.enc.Base64.stringify(encrypt.ciphertext);
// }

// /**
//  * AES 解密
//  * @param jsonStr
//  */
// export const AES_Decrypt = (jsonStr) => {
//   let plaintext = CryptoJS.AES.decrypt(jsonStr, KEY, {
//     mode: CryptoJS.mode.ECB,
//     padding: CryptoJS.pad.Pkcs7
//   }).toString(CryptoJS.enc.Utf8)

//   return plaintext
// }
