<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  mounted(){
    let that = this;
    that.fontSize();
    window.onresize= function(){
      that.fontSize();
    }
  },
  methods:{
    fontSize(){
    	var _html = document.getElementsByTagName("html")[0];

    	var w = document.documentElement.clientWidth;
    	var newin;
    	// console.log(w);
    	if(w>1500){
    		newin=1500
    	}else if(w>=1150 && w<1500){
    		newin=document.documentElement.clientWidth;
    	}else if(w<1150){
    		newin=1200
    	}
    	_html.style.fontSize = newin/120 +"px";
    }
  }
}
</script>

<style>
#app {
  font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Osaka, "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
<style lang="less" >
    @import './style/variable.less';
    @import './style/index.less';
</style>
