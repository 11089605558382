import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
		path: '/',
		redirect: '/index'
	},
	{
		path: '/home',
		component: () => import('../views/home.vue'),
		children:[
      {
    		path: '/index',
    		component: () => import('../views/index.vue'),
        meta: {
    			title: '繁星優選不動産クラウドファンディング | 安心・信頼の不動産投資',
				content:{
					keywords:'不動産投資クラウドファンディング, 不動産クラウドファンディング, 不動産ファンド, 投資機会, 日本の不動産投資, 不動産投資信託, 投資家, 不動産を運用,不動産投資クラファン, 不動産投資, 投資ファンド, 不動産投資',
					description:'不動産特定共同事業を中心としたクラウドファンディングのプラットフォーム、繁星優選では、優良な不動産プロジェクトを厳選し、投資家に提供しています。高い透明性とリスク管理を重視し、信頼性のある投資機会を提供することを使命としています。',
				},
    		}
    	},
      {
    		path: '/login',
    		component: () => import('../views/login.vue'),
        meta: {
    			title: '繁星優選ログイン'
    		}
    	},
      {
    		path: '/register',
    		component: () => import('../views/register.vue'),
        meta: {
    			title: '繁星優選無料会員登録'
    		}
    	},
		{
    		path: '/registerbefore',
    		component: () => import('../views/registerbefore.vue'),
        meta: {
    			title: '会員登録'
    		}
    	},
		{
    		path: '/bindemail',
    		component: () => import('../views/bindemail.vue'),
        	meta: {
    			title: 'メール登録'
    		}
    	},
		
      {
    		path: '/fundlist',
    		component: () => import('../views/fundlist.vue'),
        meta: {
    			title: '「専属不動産ファンド一覧 | 繁星優選クラウドファンディング」',
				content:{
					keywords:'不動産投資クラウドファンディング, 不動産クラウドファンディング, 不動産ファンド, 投資機会, 日本の不動産投資, 不動産投資信託, 投資家, 不動産を運用,不動産投資クラファン, 不動産投資, 投資ファンド, 不動産投資',
					description:'当社が提供する多様なファンド情報を一目で確認できるページです。各ファンドの詳細な情報、リスク、収益予測などを提供し、投資家が最適な投資先を選択できるようサポートします。',
				},
    		}
    	},
      {
    		path: '/flow',
    		component: () => import('../views/flow.vue'),
        meta: {
    			title: 'ご利用までの流れ',
				content:{
					keywords:'不動産投資クラウドファンディング, 不動産クラウドファンディング, 不動産ファンド, 投資機会, 日本の不動産投資, 不動産投資信託, 投資家, 不動産を運用,不動産投資クラファン, 不動産投資, 投資ファンド, 不動産投資',
					description:'繁星優選での投資プロセスを、初めての方にも分かりやすく解説します。登録から投資、そしてリターン受領までのステップを具体的に説明し、スムーズな投資体験をサポートします。',
				},
    		}
    	},
      {
    		path: '/contact',
    		component: () => import('../views/contact.vue'),
        meta: {
    			title: 'お問い合わせ',
				content:{
					keywords:'不動産投資クラウドファンディング, 不動産クラウドファンディング, 不動産ファンド, 投資機会, 日本の不動産投資, 不動産投資信託, 投資家, 不動産を運用,不動産投資クラファン, 不動産投資, 投資ファンド, 不動産投資',
					description:'繁星優選に関する質問やご相談がある場合にご利用いただける、お問い合わせ窓口です。メールでのお問い合わせを受け付けており、迅速かつ丁寧に対応いたします。',
				},
    		}
    	},
      {
    		path: '/profile',
    		component: () => import('../views/profile.vue'),
        meta: {
    			title: '運営会社',
				content:{
					keywords:'不動産投資クラウドファンディング, 不動産クラウドファンディング, 不動産ファンド, 投資機会, 日本の不動産投資, 不動産投資信託, 投資家, 不動産を運用,不動産投資クラファン, 不動産投資, 投資ファンド, 不動産投資',
					description:'繁星優選の会社の紹介ページです。',
				},
    		}
    	},
      {
    		path: '/faq',
    		component: () => import('../views/faq.vue'),
        meta: {
    			title: '「よくあるご質問（FAQ） | 繁星優選不動産クラウドファンディング」',
				content:{
					keywords:'不動産クラウドファンディング FAQ, 不動産投資, 投資ファンド, 谷町君, 繁星優選, 投資初心者, 不動産投資の疑問',
					description:'投資家からよく寄せられる質問をまとめたページです。繁星優選のサービスや投資プロセスに関する疑問を解消し、安心して投資活動を行えるよう支援します。',
				},
    		}
    	},
      {
    		path: '/funddetail',
    		component: () => import('../views/funddetail.vue'),
        meta: {
    			title: '繁星優選クラウドファンディング'
    		}
    	},
      {
    		path: '/userinfo',
    		component: () => import('../views/userinfo.vue'),
        meta: {
    			title: '個人情報'
    		}
    	},
      {
    		path: '/mypage',
    		component: () => import('../views/mypage.vue'),
        meta: {
    			title: 'マイページ'
    		}
    	},
      {
    		path: '/noticelist',
    		component: () => import('../views/noticelist.vue'),
        meta: {
    			title: 'お知らせ'
    		}
    	},
      {
    		path: '/applylist',
    		component: () => import('../views/applylist.vue'),
        meta: {
    			title: '申込履歴'
    		}
    	},
      {
    		path: '/investlist',
    		component: () => import('../views/investlist.vue'),
        meta: {
    			title: 'マイファンド'
    		}
    	},
      {
    		path: '/investpdf',
    		component: () => import('../views/investpdf.vue'),
        meta: {
    			title: '交付書面一覧'
    		}
    	},
      {
    		path: '/incomedetail',
    		component: () => import('../views/incomedetail.vue'),
        meta: {
    			title: '收益明細'
    		}
    	},
      {
    		path: '/accounthistory',
    		component: () => import('../views/accounthistory.vue'),
        meta: {
    			title: '入出金一覧'
    		}
    	},
      {
    		path: '/reissue',
    		component: () => import('../views/reissue.vue'),
        meta: {
    			title: 'パスワード再設定申請'
    		}
    	}
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
