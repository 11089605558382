import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'
import './plugins/element.js'
import serverJs from './utils/server.js'
import $ from 'jquery';
import 'font-awesome/css/font-awesome.css';
import AESEncrypt from './utils/aes.js'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import Api from './api/Api.js'
Vue.use(Api);
Vue.prototype._Api = Api;
Vue.use(AESEncrypt);
Vue.use(serverJs);
Vue.prototype.SV = serverJs;
Vue.prototype.AE = AESEncrypt;
Vue.config.productionTip = false
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
console.log = function(){

};
router.afterEach((to,from)=>{
	if (to.meta.title) {
	  document.title = to.meta.title
	}
	if(to.meta.content){
	  let head = document.getElementsByTagName('head');
	  let meta = document.createElement('meta');
	  document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
	  document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
	  meta.content = to.meta.content;
	  head[0].appendChild(meta)
	}
  }) 
function setCookie(c_name,value,day,domain){
		var exdate=new Date();
		exdate.setTime(exdate.getTime()+day*24*60*60*1000);
		document.cookie=c_name+ "=" +escape(value)+((day==null) ? "" : ";expires="+exdate.toGMTString())+";path=/;domain="+domain;
}
function getCookie(cname){
	var name = cname + "=";
	var ca = document.cookie.split(';');
	for(var i=0; i<ca.length; i++)
	{
		var c = ca[i].trim();
		if (c.indexOf(name)==0) return c.substring(name.length,c.length);
	}
	return "";
}
